.app {
  min-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  text-align: center;
}

.header h2 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.tagList {
  width: 100%;
  max-width: 90vw;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
}

.loopSlider .inner {
  display: flex;
  width: fit-content;
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: var(--direction);
  animation-duration: var(--duration);
}

.tag {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 12em;
  margin: 0 auto;
  margin: 0 auto;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color: #334155;
  border-radius: 0.4rem;
  padding: 0.7rem 1rem;
  margin-right: 1rem;
  box-shadow:
    0 0.1rem 0.2rem rgb(0 0 0 / 20%),
    0 0.1rem 0.5rem rgb(0 0 0 / 30%),
    0 0.2rem 1.5rem rgb(0 0 0 / 40%);
}

.tag span {
  font-size: 1.2rem;
  color: #64748b;
}

.fade {
  pointer-events: none;
  background: linear-gradient(#1e293b, transparent 5%, transparent 95%, #1e293b);
  position: absolute;
  inset: 0;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 3));
  }
}

@media (min-width: 768px) {
  .tagList {
    width: 80vw;
  }
}

@media (min-width: 1024px) {
  .tagList {
    width: 70vw;
  }
}

@media (min-width: 1280px) {
  .tagList {
    width: 60vw;
  }
}
