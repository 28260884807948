.container {
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.step {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.step-content {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.step h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  line-height: 1.6;
  flex-grow: 1;
}

.step-icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ffcc00;
}

@media (min-width: 1050px) {
  .steps {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .step {
    flex: 1;
    max-width: 300px;
  }

  .step-content {
    height: 100%;
  }
}
